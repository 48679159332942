<template>
  <div class="mainPolicyContainer">
  <div class="headingContainer headingCenter"><span class="heading"> API Bridge – Terms & Conditions </span></div>
  <ol>
<li> <span class="font-bold">General Information (API Bridge):</span> The API Bridge provides User Interface (UI)
to manage API functionality for order management and trade management. API Bridge
offers several risk management controls such as Max Orders Per Minute, Max Trades
Per Day, Max Order Quantity, Max Sym Pos among others. Users are advised to trade
only after full understanding of the UI controls in API Bridge.</li>
<li> <span class="font-bold"> No guarantees or warranties:</span> User should be aware that using API service with
API Bridge involves many uncertain factors and complex hardware, software, systems,
communication lines, peripherals etc. The Company does not make any commitment,
warranty or promises that this API Bridge will be available to at all times without any
interruption/fail.</li>
<li> <span class="font-bold"> Limitation of Liability:</span> User should carefully consider whether this API based
trading is suitable in light of his financial condition or otherwise. Company shall not be
responsible for lost profits, revenues, or data; financial losses; or indirect, special,
consequential, exemplary, or punitive damages. Users are hereby informed, and by
proceeding to trade duly acknowledge and accept that there can be no guarantee of
profits or no exception from losses while executing orders by using the API Facility. All
trades are subject to market risk and are to User’s trading account.</li>
<li><span class="font-bold"> Confidentiality:</span> Confidential information including credentials (such as trading
passwords, API keys and secrets, and Client IDs) and order history (details of orders
submitted, modified, cancelled) are intended to be used only by User. This confidential
information is stored on User’s local computer only, and not accessible to Company. The
Company may have access to diagnostic information related to application crash or
performance issues which is used for betterment of API Bridge</li>
<li><span class="font-bold"> Trade Authorization:</span> All trades placed via API Bridge shall be manually
authorized by User. The User acknowledges to manually login, authorize and supervise
all trades via API Bridge.</li>
</ol>
<div class="headingContainer subheader ">
WARRANTIES, DISCLAIMERS:

</div>
<ol>
<li> Amibroker / Trading View / MT4 / MT5 / Ninja Trader are product names of Third
Party Applications (TPA).</li>
<li> Company or Associates has no association with TPA and cannot be held liable on
any account arising from the use of TPA or any information/analysis derived under the
scope of this proposal by use of TPA by any of the parties hereto. The customer should
use TPA software/api at his/her own risk. Any trading decisions made by
analysis/information through the TPA will be the sole responsibility of customer.</li>
<li> All Content/Deliverables provided herein is provided on an “as is, where is” basis
without any warranty or representation whatsoever. Company disclaims any and all
responsibility for any loss or claim including but not limited to any direct, special, indirect,
incidental, exemplary or consequential damages resulting from Customer ’s or any end
user’s use of or inability to access or use the Content or any inaccuracy therein. Further,
Company shall not be liable for claims/damages in case of any error or for any delay or
failure in transmission of the information.</li>
<li> Company makes no warranties, either expressed or implied as to the results to be
obtained by any person/organization or entity from the use of the information or data
either whole or in part, supplied to the Customer. Company makes no express or implied
warranties of merchantability or fitness or suitability or availability for a particular purpose
or use with respect to the information or its content/deliverables. Content/Deliverables in
general are not error-free and the customer agrees that the existence of such errors shall
not constitute a breach of this proposal.</li>
</ol>
<div class="headingContainer subheader">
INDEMNIFICATION AND LIMITATION OF LIABILITY:
</div>
<p class="margin-left-align">
The Customer shall defend, indemnify and hold Company and its employees and agents
harmless from and against, any and all suits, actions and proceedings, claims, liabilities,
losses, damages, costs and expenses, including reasonable outside attorneys’ fees,
arising directly or indirectly in connection with:
</p>
  <ol type="a">
<li>) a violation of any law, regulation or order of any government or judicial authority
by him/her; or</li>
<li>) any act omission, fraud, negligence or default or the breach of any of his/her
obligations or representations hereunder, by him/her or his/her employees and
agents; or</li>
<li>) any infringement of any intellectual property right of any third Party; or</li>
<li>) Disclosure of confidential information to any third party except with the written
prior consent of the disclosing party.</li>
<li>) Disputes: Any dispute arising out of or relating to this Agreement shall be first
attempted to be resolved through mutual discussions and conciliation between
the</li>
</ol>
<p class="margin-left-align margin-5px">
Parties. 
</p>
</div>
</template>

<script>
import "../assets/css/usage-policy.css";
export default {

}
</script>

<style scoped>

body{
    /* background-color:#fff ; */
    color: #000;
    margin: auto;
    font-weight: 300;
    margin: 0 30px;
    
}
ol{
    margin: 0px 30px;
    font-family: Arial,serif;
    font-weight: 500;
    font-style: normal;
    text-decoration: none;
}

li{
    margin-bottom: 20px;
}

p{
    font-weight: 400;
}
</style>